import * as React from 'react'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'

import Layout from '../../components/Layout'
import { device } from '../../components/Styles'
import SEO from '../../components/Seo'

const Container = styled.div`
  margin: 0rem auto;
  padding: 1rem;

  @media ${device.laptop} {
    width: var(--width);
    padding: 0;
  }

  h1 {
    text-align: center;
  }
`
const Posts = styled.ul`
  padding: 1rem;
  border-radius: 1rem;
  margin: 0;
`
const Item = styled.li`
  list-style: none;
  padding: 2rem;
  margin-bottom: 4rem;
  background-color: var(--color-gray-100);
`
const Date = styled.div`
  color: var(--color-gray-400);
`
const Title = styled.div`
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.4em;

  > a {
    text-decoration: none;
    border-bottom: 2px solid var(--color-pink-400);
  }
`
const ItemTop = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`
const Excerpt = styled.div``

interface Props {
  data: {
    allMdx: {
      nodes: {
        id: string
        slug: string
        excerpt: string
        frontmatter: {
          title: string
          date: string
        }
      }[]
    }
  }
}

const BlogPage: React.FC<Props> = ({ data }) => {
  return (
    <Layout>
      <SEO title="Blog" />
      <Container>
        <h1>Blog</h1>
        <Posts>
          {data.allMdx.nodes.map((node) => (
            <Item key={node.id}>
              <ItemTop>
                <Title>
                  <Link to={`/blog/${node.slug}`}>{node.frontmatter.title}</Link>
                </Title>
                <Date>{node.frontmatter.date}</Date>
              </ItemTop>
              <Excerpt>{node.excerpt}</Excerpt>
            </Item>
          ))}
        </Posts>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMdx {
      nodes {
        id
        slug
        frontmatter {
          title
          date
        }
        excerpt
      }
    }
  }
`

export default BlogPage
